import MainLayout from "../components/Layout/MainLayout"
import classnames from "../helpers/classnames"
import Footer from "../components/footer"
import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Logo from "../components/logo/logo"
import Section from "../components/Section"
import style from "../styles/page-7-day-free-trial.module.scss"
import Button from "../components/button"
import modalStyles from "../components/popup/popup.module.scss"

export default function Page({
                                   data,
                                   location,
                                   children,
                                   ...rest
                                 }) {
  const { b } = data.site.siteMetadata.pricingConfiguration;
  return (
    <MainLayout {...rest}>

      <div className={classnames("text-center my-32")}>
        <a href="/#" title="MINDWORX" className="logo">
          <Logo style={{ width: "90px", height: "90px" }} />
        </a>
      </div>

      <Section
        title={<>Start your 7-day free trial</>}
        className={classnames(
          "text-center pt-0"
        )}
      >

        <div className={classnames("my-32")}>
          There will be <strong>3 weekly payments</strong> of 146€<br/>
          after the free trial ends
        </div>

        <div className={classnames("my-32")}>
          Simply cancel online before the trial<br/>
          ends <strong>to not be charged</strong>
        </div>

        <div className={classnames("my-32")}>
          As a reminder, we’ll email you<br/>
          <strong>2 days before it ends</strong>
        </div>

        <div className={classnames("my-32")}>
          <strong>Lifetime access</strong><br/>
          (unless you cancel)
        </div>

        <div>
          <Button
            href={b.complete.url}
            target="blank"
            id="cta-set-up-free-trial"
          >SET UP FREE TRIAL</Button>
        </div>

      </Section>

      <Footer/>
    </MainLayout>
  )
}

export const pageQuery = graphql`
  query PageQuery {
  site {
    siteMetadata {
      pricingConfiguration {
        b {
          ebook {
            price
            oldPrice
            url
          }
          complete {
            price
            oldPrice
            url
          }
          video {
            price
            oldPrice
            url
          }
        }
      }
    }
  }
  }
`
